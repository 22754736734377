import createClient from "openapi-fetch";
import type { paths, components, operations } from "./apiv2";
import { REST_SERVING_URL } from "../backendConfig";
import { getGlobalUserContextValues } from "../contexts/UserContext";

// See docs at https://github.com/drwpow/openapi-typescript/tree/main/packages/openapi-fetch
export const apiV2Client = createClient<paths>({ baseUrl: REST_SERVING_URL });

const apiErrorHandler = (error: unknown) => {
  console.log("apiErrorHandler: ", error);
  // TODO: Add datadogRum.addError here to centralize error reporting once v2 migration is completed
};

// Middleware
apiV2Client.use({
  async onRequest({ request }) {
    // Add global headers
    // Content-Type header is set by openapi-fetch automatically
    const { customerId, getJwt } = getGlobalUserContextValues();
    const jwt = await getJwt();

    if (customerId) {
      request.headers.set("customer-id", customerId);
    }
    if (jwt) {
      request.headers.set("Authorization", `Bearer ${jwt}`);
    }
    return request;
  },

  async onResponse({ response }) {
    if (!response.ok) {
      const error = await response.clone().json();
      apiErrorHandler(error || response.statusText);
    }
    return response;
  },

  async onError({ error }) {
    apiErrorHandler(error);
  }
});

type ApiV2 = components["schemas"];

// This type extracts the query parameter type from operations where query
// is a parameter. If query isn't a parameter, the resulting type is never.
type ApiV2QueryParams<T extends keyof operations> =
  operations[T]["parameters"]["query" extends keyof operations[T]["parameters"] ? "query" : never];

export type { ApiV2, ApiV2QueryParams };

export type ApiKey = ApiV2["ApiKey"];
export type ApiKeyRole = ApiV2["ApiKeyRole"];
export type CustomerRole = ApiV2["ApiRole"];
export type Corpus = ApiV2["Corpus"];
export type CorpusCustomDimensions = Exclude<Corpus["custom_dimensions"], undefined>;
export type CorpusFilterAttributes = Exclude<Corpus["filter_attributes"], undefined>;
export type CustomDimension = CorpusCustomDimensions extends Array<infer T> ? T : never;
export type CustomerSpecificReranker = ApiV2["CustomerSpecificReranker"];
export type Document = ApiV2["Document"];
export type DocumentPart = ApiV2["DocumentPart"];
export type FilterAttribute = ApiV2["FilterAttribute"];
export type FilterAttributeLevel = FilterAttribute["level"];
export type FilterAttributeType = FilterAttribute["type"];
export type GenerationPreset = ApiV2["GenerationPreset"];
export type SearchResult = ApiV2["IndividualSearchResult"];
export type Table = ApiV2["Table"];
export type TableHeader = ApiV2["Header"];
export type TableRow = ApiV2["Row"];
export type TableCell = ApiV2["Cell"];
export type Job = ApiV2["Job"];
export type JobState = ApiV2["JobState"];
export type JobType = Job["type"];
export type Llm = ApiV2["LLM"];
export type MmrReranker = ApiV2["MMRReranker"];
export type Query = ApiV2["QueryRequest"];
export type QueryResponse = ApiV2["QueryFullResponse"];
export type QueryCustomDimension = {
  name: string;
  weight: number;
};
export type QueryHistorySummary = ApiV2["QueryHistorySummary"];
export type QueryHistory = ApiV2["QueryHistory"];
export type QueryHistorySpan = ApiV2["QueryHistorySpan"];
export type RephraseSpan = ApiV2["RephraseSpan"];
export type SearchSpan = ApiV2["SearchSpan"];
export type RerankSpan = ApiV2["RerankSpan"];
export type GenerationSpan = ApiV2["GenerationSpan"];
export type FcsSpan = ApiV2["FactualConsistencyScoreSpan"];
export type UdfReranker = ApiV2["UserFunctionReranker"];
export type AppClient = ApiV2["AppClient"];
export type UploadFileResponse = operations["uploadFile"]["responses"]["201"]["content"]["application/json"];
export type Turn = ApiV2["Turn"];

export const JobStateEnum: { [key in JobState]: key } = {
  unknown: "unknown",
  queued: "queued",
  started: "started",
  completed: "completed",
  failed: "failed",
  failed_will_retry: "failed_will_retry",
  aborted: "aborted"
};

export const JobTypeEnum: { [key in Exclude<JobType, undefined>]: key } = {
  rebuild_vector_index: "rebuild_vector_index",
  replace_filter_attributes: "replace_filter_attributes",
  unknown: "unknown"
};

// encapsulates the logic of throwing an error if the request fails
export async function withApiErrorHandling<T, U>(request: Promise<{ data?: T; error?: U; response: Response }>) {
  const { data, error, response } = await request;
  if (error) {
    throw error;
  }

  return {
    data: data!, // data is guaranteed if there is no error
    response
  };
}
